import styled from "styled-components"
import breakpoints from "../../theme/breakpoints"

const HeaderAdress = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 0.8rem;
  @media (min-width: ${breakpoints.sm}) {
    font-size: 0.9rem;
  }
  @media (min-width: ${breakpoints.md}) {
    font-size: 1rem;
  }
  line-height: 1.5;
`

export default HeaderAdress
