const theme = {
  sectionWidth: "1000px",
  bgMain: "#fff",
  bgHeader: "#333",
  breakpoints: ["321px", "481px", "801px", "1201px", "1441px"],
  baseFontSize: "20px",
  baseLineHeight: 1.41,
  headerFontFamily: ["Montserrat", "sans-serif"],
  bodyFontFamily: ["Lato", "sans-serif"],
  fontSizes: ["1rem", "2.833rem", "2rem", "1.388rem", "1rem", "1rem", "1rem"],
  lineHeights: [
    "1rem",
    "3.666rem",
    "2.444rem",
    "2.444rem",
    "1.41rem",
    "1.41rem",
    "1.41rem",
  ],
  colors: {
    blue: "#07c",
    lightgray: "#f6f6ff",
  },
  // space: [0, 8, 16, 24, 32], // 1rem = 16px
  space: [0, 9, 18, 27, 36], // 1rem = 18px
  fonts: {
    sans: "system-ui, sans-serif",
    mono: "Menlo, monospace",
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)",
  },
}

export default theme
