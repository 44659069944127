import theme from "./index.js"

const breakpoints = {
  xs: theme.breakpoints[0],
  sm: theme.breakpoints[1],
  md: theme.breakpoints[2],
  lg: theme.breakpoints[3],
  xl: theme.breakpoints[4],
}

export default breakpoints
