import React from "react"
import PropTypes from "prop-types"

import FigureStyled from "./styled/FigureStyled"
import FigureCaption from "./styled/FigureCaption"

const Figure = props => {
  const { children, caption } = props
  return (
    <FigureStyled {...props}>
      {children}
      {caption && <FigureCaption>{caption}</FigureCaption>}
    </FigureStyled>
  )
}

export default Figure

Figure.propTypes = {
  id: PropTypes.string,
  caption: PropTypes.string,
  children: PropTypes.node,
}
