import styled from "styled-components"
import breakpoints from "../../../theme/breakpoints"
import theme from "../../../theme"

const LogoStyled = styled.div`
  margin: 0;
  color: #fff;
  font-family: ${theme.bodyFontFamily.join(", ")};
  font-weight: normal;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.9rem;
  line-height: 1.1;
  @media (min-width: ${breakpoints.xs}) {
    font-size: 1rem;
    line-height: 1;
  }
  @media (min-width: ${breakpoints.sm}) {
    font-size: 1.3rem;
    line-height: 1;
  }
  @media (min-width: ${breakpoints.md}) {
    font-size: 1.5rem;
    line-height: 1;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 1.8rem;
    line-height: 1;
  }
`

export default LogoStyled
