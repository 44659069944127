import PropTypes from "prop-types"
import React from "react"
import LogoImg from "./styled/LogoImg"
import LogoText from "./styled/LogoText"
import LogoTextBlock from "./styled/LogoTextBlock"
import LogoStyled from "./styled/LogoStyled"
import LinkStyled from "./styled/LinkStyled"

const Logo = ({ to, img, text1, text2, as, children }) => (
  <LinkStyled to={to}>
    <LogoStyled as={as}>
      {img && <LogoImg src={img} alt="Logo" />}
      <LogoTextBlock>
        {text1 && <LogoText>{text1} </LogoText>}
        {text2 && <LogoText>{text2} </LogoText>}
      </LogoTextBlock>
      {children}
    </LogoStyled>
  </LinkStyled>
)

Logo.propTypes = {
  to: PropTypes.string.isRequired,
  img: PropTypes.any,
  text: PropTypes.string,
  children: PropTypes.node,
}

Logo.defaultProps = {
  as: "div",
}

export default Logo
