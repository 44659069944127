import styled from "styled-components"
import breakpoints from "../../../theme/breakpoints"
import theme from "../../../theme"

const LogoImg = styled.img`
  flex-shrink: 0;
  filter: invert(1);
  display: inline-block;
  width: 32px;
  margin: 0;
  margin-right: 0.5rem;
  @media (min-width: ${breakpoints.xs}) {
    width: 40px;
  }
  @media (min-width: ${breakpoints.sm}) {
    width: 54px;
  }
  @media (min-width: ${breakpoints.md}) {
    width: 38px;
  }
`

export default LogoImg
