import lazySizes from "lazysizes"
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import "typeface-montserrat"
import "typeface-lato"

import CookieConsent from "react-cookie-consent"
import GlobalStyle from "./styled/GlobalStyle"
import { ThemeProvider } from "styled-components"
import { Flex } from "rebass"

import theme from "../../theme"
import Container from "../Container"
import Header from "../Header"
import HeaderAddress from "../HeaderAddress"
import Logo from "../Logo"
import Main from "../Main"
import Footer from "../Footer"
import Menu from "../Menu"
import MenuItem from "../MenuItem"
import NoWrap from "../NoWrap"
import Flag from "../Flag"

import englishFlag from "../../img/0024/UK.png"

// loads all images directly after page is loaded
// lazySizes.cfg.preloadAfterLoad = true

let browser = {}
// Wrap the require in check for window
if (typeof window !== `undefined`) {
  const bowser = require("bowser")
  browser = bowser.getParser(window.navigator.userAgent).getBrowser()
} else {
  browser.name = "undefined"
  browser.version = ""
}

const Layout = ({ children, props }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            date
          }
        }
      }
    `
  )
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <GlobalStyle />
        <Main>{children}</Main>
        <Footer
          revision={site.siteMetadata.date}
          browser={browser.name + " " + browser.version}
        >
          <span>
            <Link to="/">© Karl Anders</Link>
          </span>
          <span>
            <a href="https://toothrobber.com" target="_blank">
              <Flag src={englishFlag} alt="English Flag" />
              toothrobber.com
            </a>
          </span>

          {/* <span>Revision: {site.siteMetadata.date}</span>
          <span>
            Browser: {}
          </span> */}
        </Footer>
        <CookieConsent
          location="bottom"
          buttonText="Ich stimme zu"
          cookieName="zahnraeuber"
          style={{ background: "#465A65" }}
          buttonStyle={{ color: "#4e503b", fontSize: "15px" }}
          expires={150}
        >
          Mit Nutzung dieser Webseite stimme ich der Verwendung von Cookies zu.
        </CookieConsent>
      </Container>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
